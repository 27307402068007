<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加网点</en-button>
      <en-button type="primary" @click="operation.add.click">导出</en-button>
    </template>
    <tabs-maintain
      :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['SRYTNTSTAT']) }"
      :props="{ value: 'code', label: 'message' }"
      v-model="tabs.statusCode"
      :method="table.get"
    >
    </tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :data="table.data"
          :method="table.get"
          :paging="table.paging"
          :height="height - 55"
          pagination
          :loading="table.loading"
          code="SPTNTFLD"
          :config="table.config"
        >
          <template #NAME="{ row }: { row: EnosprayCommonDefinitions['TenantQueryDto'] }">
            <en-button type="primary" text @click="table.edit.click">{{ row.name }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '服务商'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="服务商名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="服务商编码" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="选择用户">
        <select-maintain
          v-model="detail.form.data.users"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          multiple
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
// import { h } from 'vue'
import { render } from 'vue'
import { EnMessageBox } from '@enocloud/components'

export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            // this.detail.form.init()
            // this.detail.visible = true
          }
        }
      },
      tabs: {
        statusCode: ''
      },
      form: {
        is: 'form',
        data: {}
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/tenant/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          }
        },
        children: {
          edit: {
            click() {}
          }
        },
        config: {
          NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'name'
              }
            }
          },
          SHORT_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'shortName'
              }
            }
          },
          PARENT: {
            header: {
              filter: {
                type: 'select',
                field: 'parentCode',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['TNTPRT']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          PAINT_TYPES: {
            header: {
              filter: {
                type: 'select',
                field: 'paintType',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['PAINTTYPE']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          CHARGING_STANDARD: {
            header: {
              filter: {
                type: 'select',
                field: 'chargingStandard',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CHGLEVEL']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          SETTLEMENT_POLICY: {
            header: {
              filter: {
                type: 'select',
                field: 'businessSettlementPolicy',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['BNSSTLMTPOL']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'salesman'
              }
            }
          },

          CUSTOMER_AGENCY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerAgencyId',

                props: {
                  ajax: {
                    action: 'GET /enospray/common/customer/agency',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          SUPERVISOR_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'supervisor'
              }
            }
          },
          SIGNED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'signedBy'
              }
            }
          },
          EXPECTED_EXECUTING_START_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['expectedExecutingStartDate', 'expectedExecutingEndDate']
              }
            }
          },
          ACTUAL_EXECUTING_START_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['actualExecutingStartDate', 'actualExecutingEndDate']
              }
            }
          },
          EXECUTED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'executedBy'
              }
            }
          },
          EXECUTED_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['executedStartDate', 'executedEndDate']
              }
            }
          },
          MANAGER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'manager'
              }
            }
          },
          SERVICE_ERP: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceErp'
              }
            }
          },
          QUOTATION_SYSTEM: {
            header: {
              filter: {
                type: 'text',
                field: 'quotationSystem'
              }
            }
          },
          ENOCH_ERP_USAGE_PURPOSE: {
            header: {
              filter: {
                type: 'select',
                field: 'enochErpUsagePurpose',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['EHERPUSPPE']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          LAST_ERP_TRAINING_DATE: {},
          BONUS_POLICY: {
            header: {
              filter: {
                type: 'select',
                field: 'bonusPolicy',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['BNSPOL']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          CHECK_SURFACE_COUNT: {
            header: {
              filter: {
                type: 'text',
                field: 'checkSurfaceCount'
              }
            }
          },
          EQUIPMENT_INSTALL_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'equipmentInstallById',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          EQUIPMENT_INSTALL_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['equipmentInstallDateStart', 'equipmentInstallDateEnd']
              }
            }
          },
          PERFORMANCE_SEND_POLICY: {
            header: {
              filter: {
                type: 'select',
                field: 'performanceSendPolicy',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['PFMSNDPOL']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          ERP_EXECUTING_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['erpExecutingDateStart', 'erpExecutingDateEnd']
              }
            }
          },
          JOIN_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['joinDateStart', 'joinDateEnd']
              }
            }
          },
          PREPARING_START_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['preparingStartDateStart', 'preparingStartDateEnd']
              }
            }
          },
          ACTUAL_EXECUTING_START_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['actualExecutingStartDateStart', 'actualExecutingStartDateEnd']
              }
            }
          },
          EQUIPMENT_INSTALL_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['equipmentInstallDateStart', 'equipmentInstallDateEnd']
              }
            }
          },
          CATEGORY: {
            header: {
              filter: {
                type: 'code',
                field: 'category',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['SRYTNTCGY']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          COOPERATION_AGREEMENT_URL: {}
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',

            ajax: {
              get: {
                action: 'GET /enospray/common/customer/agency/:agencyId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/common/customer/agency',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enospray/common/customer/agency',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入服务商名称', trigger: 'blur' }],
              serialNo: [{ required: true, message: '请输入服务商编码', trigger: 'blur' }],
              users: [{ type: 'array', required: true, message: '请选择用户', trigger: 'blur' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      setting: {
        visible: false,
        children: {
          form: {
            is: 'form'
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
